import { DomainEvent } from 'domain-objects';

/**
 * an event which specifies that an async task has failed to execute
 */
export interface AsyncTaskFailureEvent {
  /**
   * the external id of the task that was updated
   */
  asyncTaskExternalId: string;

  /**
   * when the async task was updated
   */
  occuredAt: string;

  /**
   * the message of the error that caused the failure
   */
  errorMessage: string;
}

export class AsyncTaskFailureEvent
  extends DomainEvent<AsyncTaskFailureEvent>
  implements AsyncTaskFailureEvent
{
  public static unique = ['asyncTaskExternalId', 'occuredAt'];
}
