// import { Stage, stage } from './environment';
import { Stage, stage } from './environment';

const isProdEnv = stage === Stage.PRODUCTION;

/**
 * note: this is different from backend service configs in that there can be no private secrets in a clientside program
 *
 * therefore, all config values can be simply hardcoded and checked in
 */
// TODO: get whodis shit for dev environment v prod environment
export const getConfig = () => ({
  ahction: {
    host: isProdEnv
      ? `https://www.aetherauctions.dev`
      : `https://localhost.aetherauctions.dev`,
  },
  google: {
    maps: {
      apiKey: 'AIzaSyCpAT3i5h3sff8xHnCcIM69btsc4Osxbx8',
    },
  },
  whodis: {
    users: isProdEnv
      ? {
          directoryUuid: '16ae2197-2902-43f1-a97a-542ec8aaae85',
          clientUuid: 'de862f52-077b-42ec-bfb5-320a1f4c8879',
        }
      : {
          directoryUuid: 'ac3a4108-6f39-4084-b959-1021e0e3051d',
          clientUuid: '2a1fe455-3273-49c7-8035-d7be425e7e74',
        },
  },
});
