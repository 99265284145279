import { HasMetadata } from 'simple-type-guards';

import { AsyncTask } from '../../../domain/objects/AsyncTask';
import { AsyncTaskUpdatedEvent } from '../../../domain/objects/AsyncTaskUpdatedEvent';
import { asyncTaskUpdatedEventStream } from '../../events/asyncTaskUpdatedEventStream';
import { db } from '../db';
import { findByUnique } from './findByUnique';

export const upsert = async <T extends AsyncTask>({
  task,
}: {
  task: T;
}): Promise<HasMetadata<T>> => {
  // do the upsert
  await db.asyncTask.put(task);
  const upsertedTask = (await findByUnique<T>({
    externalId: task.externalId,
  }))!;

  // emit an event that it was updated
  asyncTaskUpdatedEventStream.publish(
    new AsyncTaskUpdatedEvent({
      asyncTaskExternalId: upsertedTask.externalId,
      occuredAt: new Date().toISOString(),
      asyncTaskStatus: upsertedTask.status,
    }),
  );

  // return the event
  return upsertedTask;
};
