import { getAuthorizationHeader as getAuthorizationHeaderFromWhodis } from 'whodis-react';
import { storage } from 'whodis-react-storage-browser';

import { BackupLoginGroup } from '../../../../domain/constants';
import { getAuthedBackupLoginGroup } from '../../../../hooks/useAuthedBackupLoginGroup';
import { Stage, stage } from '../../../../utils/environment';

const getAuthorizationHeaderFromBackupLogin = () => {
  // get authed backup login group
  const authedBackupLoginGroup = getAuthedBackupLoginGroup();

  // if aether, return aether auth token
  if (authedBackupLoginGroup === BackupLoginGroup.AETHER)
    return '95710440e54a9b25750113dbarq7811507b3499c65e58b7f18aa75301dvk21cb';

  // if demo, return aether auth token
  if (authedBackupLoginGroup === BackupLoginGroup.DEMO)
    return '9e0355437f81b65e92534854947790e5b1702804b26a56bcb326f185e156920d';

  // else return empty string
  return '';
};

const GATEWAY_HOST =
  stage === Stage.PRODUCTION
    ? 'https://api.aetherauctions.dev'
    : 'https://dapi.aetherauctions.dev';

export const fetchAgainstSvcGateway = async ({
  endpoint,
  data,
  skip,
}: {
  endpoint: string;
  data: Record<string, any>;
  skip?: boolean;
}) => {
  // if there's a authedBackupLoginGroup, set the authorization code to either aethers or demos and put that in the headers, else use the getAuthedHeader method (empty string if no one is logged in)
  const authorization: string =
    (await getAuthorizationHeaderFromWhodis({ storage })) ||
    getAuthorizationHeaderFromBackupLogin();
  if (skip) return undefined;
  const options = {
    method: 'POST',
    mode: 'cors' as const,
    credentials: 'include' as const, // send cookies to same origin
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: authorization,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${GATEWAY_HOST}/${endpoint.replace(/^\//, '')}`,
    options,
  );
  const responseData = await response.json();
  if (responseData.errorMessage) throw new Error(responseData.errorMessage);
  return responseData;
};
