import { isPresent } from 'simple-type-guards';

import { mutationUpsertLot } from '../../../data/clients/svcGateway/mutations/mutationUpsertLot';
import { clearQueryGetLotCache } from '../../../data/clients/svcGateway/queries/useQueryGetLot';
import { asyncTaskDao } from '../../../data/dao/asyncTaskDao';
import { locallyPersistedPhotoDao } from '../../../data/dao/locallyPersistedPhotoDao';
import {
  AsyncTaskExecutorImplementation,
  AsyncTaskStatus,
} from '../../../domain/objects/AsyncTask';
import { AsyncTaskUploadPhoto } from '../../../domain/objects/AsyncTaskUploadPhoto';
import { AsyncTaskUpsertLot } from '../../../domain/objects/AsyncTaskUpsertLot';
import { getBestPhotoFromAsyncTaskUploadPhoto } from '../../photo/asyncTasks/asyncTaskUploadPhoto/getBestPhotoFromAsyncTaskUploadPhoto';

export const getSortedPhotoUrlsFromUpsertLotTasks = async (input: {
  lotUpsertTask: AsyncTaskUpsertLot;
  photoUploadTasks: AsyncTaskUploadPhoto[];
}) =>
  Promise.all(
    input.photoUploadTasks
      .sort((a, b) => (a.externalId < b.externalId ? -1 : 1)) // sort the tasks by externalId => sort the photos by index (since task external id includes photo index)
      .slice(0, input.lotUpsertTask.input.photosCount) // only use the first "photosCount" photos, since this task may have been updated + reduced the number of photos
      .map((thisTask) =>
        getBestPhotoFromAsyncTaskUploadPhoto({ task: thisTask }),
      ),
  );

/**
 * executes upsert lot tasks
 * - waits for all dependent photo upload tasks to fulfill
 * - then calls the upsert
 */
export const executeAsyncTaskUpsertLot: AsyncTaskExecutorImplementation<AsyncTaskUpsertLot> =
  async (task) => {
    // check that all of the photo upload requests for this lot upsert have succeeded
    const photoUploadTasks: AsyncTaskUploadPhoto[] =
      await asyncTaskDao.findAllByExternalIdStartsWith<AsyncTaskUploadPhoto>({
        prefix: `upsertLot.${task.input.auctionUuid}.${task.input.lotUuid}.uploadPhoto`,
      });
    const unfulfilledPhotoUploadTasks = photoUploadTasks.filter(
      (thisTask) => thisTask.status !== AsyncTaskStatus.FULFILLED,
    );
    if (unfulfilledPhotoUploadTasks.length)
      return {
        status: AsyncTaskStatus.WAITING,
        reason: `still waiting on ${
          unfulfilledPhotoUploadTasks.length
        } photo upload tasks: ${JSON.stringify(
          unfulfilledPhotoUploadTasks.map((thisTask) => thisTask.externalId),
        )}`,
      };

    // since they've all succeeded, we can now execute the lot upsert
    const photosUrls = await getSortedPhotoUrlsFromUpsertLotTasks({
      lotUpsertTask: task,
      photoUploadTasks,
    });
    const upsertedLot = await mutationUpsertLot({
      uuid: task.input.lotUuid,
      auctionUuid: task.input.auctionUuid,
      lotNumber: task.input.lotNumber,
      photos: photosUrls,
      title: task.input.title,
      dimensions: task.input.dimensions,
      notes: task.input.notes,
      condition: task.input.condition,
      location: task.input.location,
      startingBidInCents: task.input.startingBidInCents,
    });

    // and now that we've finished updating the lot, we can delete all of the photos from local persistance, to free up space
    await Promise.all(
      photoUploadTasks
        .map((thisTask) => thisTask.input.locallyPersistedPhotoKey)
        .filter(isPresent)
        .map(async (key) => await locallyPersistedPhotoDao.erase({ key })),
    );

    // and we can clear the cache to ensure that the old state isn't confusing folks
    await clearQueryGetLotCache({ lotUuid: task.input.lotUuid });

    // and now return the output
    return { output: { lot: upsertedLot } };
  };
