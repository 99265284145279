import { findAllByExternalIdStartsWith } from './findAllByExternalIdStartsWith';
import { findAllByStatus } from './findAllByStatus';
import { findByUnique } from './findByUnique';
import { upsert } from './upsert';

export const asyncTaskDao = {
  upsert,
  findByUnique,
  findAllByStatus,
  findAllByExternalIdStartsWith,
};
