import Head from 'next/head';
import React from 'react';

import { AppPropsWithSSR } from '../../__nonpublished_modules__/nextjs-material-ui';
import { theme } from '../../constants/theme';
import { AppProviders } from '../page-agnostic/app-providers/AppProviders';

/**
 * https://nextjs.org/docs/advanced-features/custom-app
 */
export const CustomApp = (props: AppPropsWithSSR) => {
  // render the page
  const { Component: Page, pageProps, serverSideEmotionCache } = props;
  return (
    <div>
      {/** define the default head properties */}
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <meta
          name="description"
          content="Tool for auction houses to streamline processes and move quicker to deliver more."
        />
        <title>Aether Auctions | Internal Tooling </title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Josefin+Sans"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@300;400;500"
        ></link>
      </Head>

      {/** and actually render the page + page props */}
      <AppProviders serverSideEmotionCache={serverSideEmotionCache}>
        <Page {...pageProps} />
      </AppProviders>
    </div>
  );
};
