import useLocalStorage from '@rehooks/local-storage';

import { BackupLoginGroup } from '../domain/constants';

/*
useAuthedOrganization => { authedOrganization: Organization, logIntoOrganization: ({ password }) => ORGANIZATION }

logIntoOrganization:
 - takes a password as input
 - figures out what org is
 - saves the org into local storage

 authedOrganization:
 - the org in local storage
*/

export const getBackupLoginGroupFromPassword = ({
  password,
}: {
  password: string;
}) => {
  if (password === 'autoauction') return BackupLoginGroup.AETHER;
  if (password === '8364_store-demo2022') return BackupLoginGroup.DEMO;

  return null;
};

/**
 * access authedBackupLoginGroup outside of hook
 *
 */
export const getAuthedBackupLoginGroup = () => {
  const authedBackupLoginGroup = localStorage.getItem('authedBackupLoginGroup');
  return authedBackupLoginGroup;
};

export const useAuthedBackupLoginGroup = () => {
  // define the root data store
  const [authedBackupLoginGroup, setAuthedBackupLoginGroup] =
    useLocalStorage<BackupLoginGroup | null>(`authedBackupLoginGroup`, null);

  const logIntoBackupLoginGroupWithPassword = ({
    password,
  }: {
    password: string;
  }) => {
    // get org
    const backupLoginGroup = getBackupLoginGroupFromPassword({ password });
    if (backupLoginGroup === null) {
      throw new Error('could not get backup login group for password');
    }
    // set to local storage
    setAuthedBackupLoginGroup(backupLoginGroup);
  };

  const logOutFromBackupLoginGroup = () => {
    // set organization to null
    setAuthedBackupLoginGroup(null);
  };

  return {
    authedBackupLoginGroup,
    logIntoBackupLoginGroupWithPassword,
    logOutFromBackupLoginGroup,
  };
};
