import { sub } from 'date-fns';

import { asyncTaskDao } from '../../data/dao/asyncTaskDao';
import { asyncTaskFailureEventDao } from '../../data/dao/asyncTaskFailureEventDao';
import {
  AsyncTask,
  AsyncTaskStatus,
  ASYNC_TASK_TO_BE_FULFILLED_STATUSES,
} from '../../domain/objects/AsyncTask';
import { AsyncTaskFailureEvent } from '../../domain/objects/AsyncTaskFailureEvent';
import { UnexpectedCodePathError } from '../../utils/errors/UnexpectedCodePathError';
import { log } from '../../utils/log';
import { getExecutorOfTask } from './getExecutorOfTask';

/**
 * execute an async task correctly
 * - looks up the task
 * - confirms it should still be executed
 * - marks it as attempted
 * - attempts it
 * - handles the result
 *   - custom status
 *   - success
 *   - failure (w/ retry)
 */
export const executeAsyncTask = async ({
  externalId,
}: {
  externalId: string;
}) => {
  log.debug('starting execution of async task', { externalId });

  // lookup the task's latest state
  const task: AsyncTask | null = await asyncTaskDao.findByUnique({
    externalId,
  });
  if (!task)
    throw new UnexpectedCodePathError(
      'no async tasks exists for this external id',
      { externalId },
    );

  // check that this task still needs to be executed
  if (!ASYNC_TASK_TO_BE_FULFILLED_STATUSES.includes(task.status)) {
    log.debug(
      'task will not be executed, as it is not in a to-be-fulfilled status',
      { task },
    );
    return null; // if this task is not in a "to be fulfilled" status, do nothing
  }

  // mark the task as attempted
  await asyncTaskDao.upsert({
    task: { ...task, status: AsyncTaskStatus.ATTEMPTED },
  });

  // try to execute the task
  try {
    // lookup the executor of the task
    const execute = await getExecutorOfTask({ task });

    // execute it
    const response = await execute(task);

    // if the response has a custom status, then update the status + reason
    if (response?.status) {
      log.info(
        'task successfully executed and requested to update its status',
        { task, response },
      );
      return await asyncTaskDao.upsert({
        task: { ...task, status: response.status, reason: response.reason },
      });
    }

    // otherwise, mark the response as successful and save the output
    const successfulTask: AsyncTask = await asyncTaskDao.upsert({
      task: {
        ...task,
        status: AsyncTaskStatus.FULFILLED,
        output: response?.output ?? null,
      },
    });

    // then queue any tasks that it is supposed to trigger, if any
    if (successfulTask.triggers) {
      const triggeredTask: AsyncTask | null = await asyncTaskDao.findByUnique({
        externalId: successfulTask.triggers,
      });
      if (!triggeredTask) {
        log.error('could not find triggered task by task.triggers externalId', {
          successfulTask,
          triggeredTask,
        });
        throw new UnexpectedCodePathError(
          'could not find triggered task by task.triggers externalId',
          { successfulTask, triggeredTask },
        );
      }
      await asyncTaskDao.upsert({
        task: { ...triggeredTask, status: AsyncTaskStatus.QUEUED },
      });
    }

    // and return the successful task
    log.debug('task successfully executed and completed', {
      externalId,
      setOutput: !!response?.output,
      outpu: response?.output,
    });
    return successfulTask;
  } catch (error) {
    // if the error is not an Error, dont handle it
    if (!(error instanceof Error)) throw error;

    // log out the failure
    log.error('failed to execute async task', {
      task,
      error,
      errorMessage: error.message,
    });

    // record that the attempt has failed
    await asyncTaskFailureEventDao.upsert({
      event: new AsyncTaskFailureEvent({
        asyncTaskExternalId: task.externalId,
        occuredAt: new Date().toISOString(),
        errorMessage: error.message,
      }),
    });

    // now check if we can re-queue it to retry it
    const failures =
      await asyncTaskFailureEventDao.findAllByAsyncTaskExternalId({
        asyncTaskExternalId: task.externalId,
        since: { occuredAt: sub(new Date(), { hours: 1 }).toISOString() }, // check all failures in the past 1 hour
        limit: 10, // up to 10
      });
    if (failures.length < 3) {
      // if there's been less than 3 failures in the past hour, requeue it
      log.info(
        'automatically requeuing async task since less than 3 errors in past hour',
        { task },
      );
      return await asyncTaskDao.upsert({
        task: {
          ...task,
          status: AsyncTaskStatus.QUEUED,
          reason: 'automatic retry: less than 3 errors in past hour',
        },
      });
    }

    // record that the task is in a permanently failed state
    const failedTask = await asyncTaskDao.upsert({
      task: {
        ...task,
        status: AsyncTaskStatus.FAILED,
        reason: `Caught an error 3 times in a row in past hour: "${error.message}". Please retry later.`,
      },
    });

    // and if we wern't able to requeue it, then return the failed task
    return failedTask;
  }
};
