import { HasMetadata } from 'simple-type-guards';

import { AsyncTaskFailureEvent } from '../../../domain/objects/AsyncTaskFailureEvent';
import { db } from '../db';

export const findByUnique = async ({
  asyncTaskExternalId,
  occuredAt,
}: {
  asyncTaskExternalId: string;
  occuredAt: string;
}): Promise<HasMetadata<AsyncTaskFailureEvent> | null> => {
  const task = await db.asyncTaskFailureEvent
    .where(['asyncTaskExternalId', 'occuredAt'])
    .equals([asyncTaskExternalId, occuredAt])
    .first();
  return (task as HasMetadata<AsyncTaskFailureEvent>) ?? null;
};
