import { HasMetadata } from 'simple-type-guards';

import { Lot } from '../../../../domain';
import { fetchAgainstSvcGateway } from '../util/createSvcGatewayFetcher';

export const mutationUpsertLot = async (input: {
  uuid: string;
  auctionUuid: string;
  lotNumber: number | null;
  photos: string[]; // uri[]
  title: string | null;
  dimensions: string | null;
  notes: string | null;
  condition: string | null;
  location: string | null;
  startingBidInCents: number | null; // cents, usd
}) => {
  const response: { lot: HasMetadata<Lot> } = await fetchAgainstSvcGateway({
    endpoint: '/lot/upsert',
    data: {
      uuid: input.uuid,
      auctionUuid: input.auctionUuid,
      lotNumber: input.lotNumber,
      photos: input.photos,
      title: input.title,
      dimensions: input.dimensions,
      notes: input.notes,
      condition: input.condition,
      location: input.location,
      startingBidInCents: input.startingBidInCents,
    },
  });
  return response.lot;
};
