// https://material-ui.com/style/color/
// https://ant.design/docs/spec/colors

/*
  pure colors
*/
const accent = {
  main: '#E06C7F', // salmon // original '#F17186' // better color #C64352
  secondary: 'rgba(241, 113, 134, .27)', // transparent salmon
  mainMedium: '#F38B9D', // lighter shade of aethers main branding color #FBE3E7
  mainLight: '#FBE3E7', // lighter shade of aethers main branding color #FBE3E7
  tertiary: '#6E6EA8', // aether's purple color
  tertiaryLight: '#FBE3E7', // lighter shade of aether's purple color - pick #c2c2f0 or #e6e5f0
  grayBackground: '#F4F6F8', // light gray background for cards
  grayOutline: '#BCBDBF', // outline color of the text input components
  filledButton: '#000000',
};
const white = 'rgb(255, 255, 255)';

/*
  core use case colors
*/
const border = {
  lightBackground: {
    default: 'rgba(0, 0, 0, 0.05)',
    chatBubble: {
      other: 'rgba(0, 0, 0, 0.025)',
      // self: 'rgba(245, 229, 27, 0.75)',
      // self: 'rgba(244, 247, 118, 1)',
      // self: 'rgba(250, 216, 89, 0.5)',
      self: 'rgba(243, 225, 107, 0.25)',
    },
    link: 'rgba(63, 81, 181, 0.25)',
  },
};
const text = {
  lightBackground: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    tertiary: 'rgba(0, 0, 0, 0.4)',
    placeholder: 'rgba(0, 0, 0, 0.21)',
    link: 'rgba(63, 81, 181, 1)',
  },
  darkBackground: {
    primary: 'white',
    secondary: 'white',
    placeholder: 'white',
    link: 'rgba(63, 81, 181, 1)',
  },
};

/**
 * status symbol colors
 */
const status = {
  warningRed: '#B32134',
  lightWarningRed: '#FCF5F5',
  red: '#e62020',
  green: '#228b22',
  lightGreen: '#B4E197',
};

/*
  build the full colors object and export it
*/
export const colors = {
  accent,
  white,
  border,
  text,
  status,
};
