import { createTheme } from '@mui/material/styles';

import { colors } from './colors';

export const theme = createTheme({
  palette: {
    primary: { main: colors.accent.main, contrastText: '#fff' },
    secondary: { main: colors.accent.grayBackground, contrastText: '#fff' },
    warning: { main: '#B32134', contrastText: '#fff' },
    success: { main: '#228b22', contrastText: '#fff' },
    info: {
      main: colors.text.lightBackground.primary,
      contrastText: '#fff',
    },
  },
});
