export enum BackgroundType {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

/**
 * to enable logging in with passwords, rather than whodis auth
 */
export enum BackupLoginGroup {
  AETHER = 'AETHER',
  DEMO = 'DEMO',
}

export enum TransformationMethod {
  BACKGROUND_BLUR = 'BACKGROUND_BLUR',
  BACKGROUND_WHITE = 'BACKGROUND_WHITE',
}
