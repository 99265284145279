import { HasMetadata } from 'simple-type-guards';

import { AsyncTask } from '../../../domain/objects/AsyncTask';
import { db } from '../db';

export const findAllByExternalIdStartsWith = async <T extends AsyncTask>({
  prefix,
}: {
  prefix: string;
}): Promise<HasMetadata<T>[]> => {
  const tasks = await db.asyncTask
    .where('externalId')
    .startsWith(prefix)
    .toArray();
  return tasks as HasMetadata<T>[];
};
