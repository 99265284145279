import { EmotionCache } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { ReactChild } from 'react';
import { AuthenticationProvider } from 'whodis-react';
import { storage } from 'whodis-react-storage-browser';

import { getConfig } from '../../../utils/config';
import { isCurrentlyServerSide } from '../../../utils/isCurrentlyServerSide';
import { AsyncTaskProvider } from './AsyncTaskProvider';
import { ThemeProvider } from './ThemeProvider';

/*
  pattern per https://kentcdodds.com/blog/authentication-in-react-applications
*/
export const AppProviders = ({
  children,
  serverSideEmotionCache,
}: {
  children: ReactChild;
  serverSideEmotionCache?: EmotionCache; // passed in when rendering on server side
}) => {
  const isServerSide = isCurrentlyServerSide();
  return (
    <>
      <AuthenticationProvider
        storage={storage}
        directoryUuid={getConfig().whodis.users.directoryUuid}
        clientUuid={getConfig().whodis.users.clientUuid}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {isServerSide ? null : <AsyncTaskProvider />}
          <ThemeProvider serverSideEmotionCache={serverSideEmotionCache}>
            <>{children}</>
          </ThemeProvider>
        </LocalizationProvider>
      </AuthenticationProvider>
    </>
  );
};
