import { convertBlobToBase64 } from '../../../../__nonpublished_modules__/react-image-selection/src/convertBlobToBase64';
import { uploadImage } from '../../../../data/clients/svcImages/uploadImage';
import { locallyPersistedPhotoDao } from '../../../../data/dao/locallyPersistedPhotoDao';
import { AsyncTaskExecutorImplementation } from '../../../../domain/objects/AsyncTask';
import { AsyncTaskUploadPhoto } from '../../../../domain/objects/AsyncTaskUploadPhoto';
import { UnexpectedCodePathError } from '../../../../utils/errors/UnexpectedCodePathError';
import { log } from '../../../../utils/log';

/**
 * handle upload-pending-photo request
 *
 * this async-task does a few things
 * 1. persists the photo into indexeddb, so that browser doesn't garbage-collect the blob (if browser is closed, we still need to access later)
 * 2. compresses the photos before upload, to maximize performance
 * 3. uploads the photo
 */
export const executeAsyncTaskUploadPhoto: AsyncTaskExecutorImplementation<AsyncTaskUploadPhoto> =
  async (task) => {
    // if this photo is already a hosted url, then do nothing
    const url = task.input.url;
    if (url.startsWith('https://img.aetherauctions'))
      return { output: { hostedUrl: url } };

    // now upload the photo
    const { url: hostedUrl } = task.input.locallyPersistedPhotoKey
      ? await (async () => {
          // lookup the photo by key
          const photo = await locallyPersistedPhotoDao.findByUnique({
            key: task.input.locallyPersistedPhotoKey!,
          });
          if (!photo)
            throw new UnexpectedCodePathError(
              'could not find locallyPersistedPhoto by task.input.locallyPersistedPhotoKey',
              { task },
            );
          const base64 = await convertBlobToBase64({ blob: photo.blob });
          return await uploadImage({ base64 });
        })()
      : await uploadImage({ url: task.input.url });
    log.debug('uploaded photo for task', { task });

    // and output the photo's uploaded url + the photo's local key
    return { output: { hostedUrl } };
  };
