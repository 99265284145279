import Dexie from 'dexie';

import { AsyncTask } from '../../domain/objects/AsyncTask';
import { AsyncTaskFailureEvent } from '../../domain/objects/AsyncTaskFailureEvent';
import { LocallyPersistedPhoto } from '../../domain/objects/LocallyPersistedPhoto';

/**
 * the Dexie database, configured with types for our implementation
 * - https://dexie.org/docs/Typescript
 */
export class AetherAuctionsDexieDatabase extends Dexie {
  asyncTask!: Dexie.Table<AsyncTask, string>;
  asyncTaskFailureEvent!: Dexie.Table<AsyncTaskFailureEvent, string>;
  locallyPersistedPhotoDao!: Dexie.Table<LocallyPersistedPhoto, string>;

  constructor() {
    super('aether-auctions-dixie-database');
    this.version(1).stores({
      asyncTask: '&externalId, status, requestedAt',
      asyncTaskFailureEvent:
        '&[asyncTaskExternalId+occuredAt], asyncTaskExternalId',
      locallyPersistedPhotoDao: '&key',
    });
  }
}

export const db = new AetherAuctionsDexieDatabase();
