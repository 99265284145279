import { CacheProvider, EmotionCache } from '@emotion/react';
import { ReactChild } from 'react';

import { createEmotionCache } from './createServerSideRenderableEmotionCache';

// globally create a client-side-emotion-cache that will be reused, from-memory, in each users browser
const clientSideEmotionCache = createEmotionCache();

/**
 * this Provider gives MUI + Emotion the most up to date cache that is available + supports server-side-rendering
 *
 * it knows to use a passed in serverSide cache when it is present - and to use a global clientSide cache when it is not present
 * - passed on serverSide because we dont want emotion state shared between users
 * - global on clientSide because we know only the user will have access to it
 *
 * TODO: confirm that only using global clientSideEmotionCache on clientSide, by detecting which env it is in
 */
export const EmotionCacheProviderWithSSR = ({
  children,
  serverSideEmotionCache,
}: {
  children: ReactChild;
  serverSideEmotionCache: EmotionCache | null; // require either null or the cache, so user doesn't accidentally just forget to define the prop
}) => {
  return (
    <CacheProvider
      value={serverSideEmotionCache ?? clientSideEmotionCache} // use the passed in serverSide cache if it exists; otherwise,
    >
      {children}
    </CacheProvider>
  );
};
