import { DomainEvent } from 'domain-objects';

import { AsyncTaskStatus } from './AsyncTask';

/**
 * an event which specifies that an async task has been updated
 */
export interface AsyncTaskUpdatedEvent {
  /**
   * the external id of the task that was updated
   */
  asyncTaskExternalId: string;

  /**
   * when the async task was updated
   */
  occuredAt: string;

  /**
   * the status of the async task, as of the update
   */
  asyncTaskStatus: AsyncTaskStatus;
}

export class AsyncTaskUpdatedEvent
  extends DomainEvent<AsyncTaskUpdatedEvent>
  implements AsyncTaskUpdatedEvent
{
  public static unique = ['asyncTaskExternalId', 'occuredAt'];
}
