import {
  AsyncTask,
  AsyncTaskExecutorImplementation,
  AsyncTaskExecutorType,
} from '../../domain/objects/AsyncTask';
import { UnexpectedCodePathError } from '../../utils/errors/UnexpectedCodePathError';
import { executeAsyncTaskUpsertLot } from '../lot/asyncTaskUpsertLot/executeAsyncTaskUpsertLot';
import { executeAsyncTaskUploadPhoto } from '../photo/asyncTasks/asyncTaskUploadPhoto/executeAsyncTaskUploadPhoto';

export const RegisteredInMemoryAsyncTaskExecutors = {
  executeAsyncTaskUpsertLot,
  executeAsyncTaskUploadPhoto,
};
export const getExecutorOfTask = <T extends AsyncTask>({
  task,
}: {
  task: AsyncTask;
}): AsyncTaskExecutorImplementation<T> => {
  if (
    task.executor.type === AsyncTaskExecutorType.REGISTERED_IN_MEMORY_FUNCTION
  ) {
    if (task.executor.key in RegisteredInMemoryAsyncTaskExecutors) {
      return RegisteredInMemoryAsyncTaskExecutors[
        task.executor.key as keyof typeof RegisteredInMemoryAsyncTaskExecutors
      ] as unknown as AsyncTaskExecutorImplementation<T>; // tell typescript that if it was referenced by name, then its the right executor
    }
  }

  // if we reached here, then no executor has been registered for this
  throw new UnexpectedCodePathError(
    'no executor has been registered matching the executor specified on async task',
    { task },
  );
};
