import { HasMetadata } from 'simple-type-guards';

import { LocallyPersistedPhoto } from '../../../domain/objects/LocallyPersistedPhoto';
import { db } from '../db';
import { findByUnique } from './findByUnique';

export const upsert = async ({
  photo,
}: {
  photo: LocallyPersistedPhoto;
}): Promise<HasMetadata<LocallyPersistedPhoto>> => {
  await db.locallyPersistedPhotoDao.put(photo);
  const upserted = (await findByUnique({
    key: photo.key,
  }))!;
  return upserted;
};
