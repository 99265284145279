import { HasMetadata } from 'simple-type-guards';

import { AsyncTaskFailureEvent } from '../../../domain/objects/AsyncTaskFailureEvent';
import { db } from '../db';

export const findAllByAsyncTaskExternalId = async ({
  asyncTaskExternalId,
  since,
  limit,
}: {
  asyncTaskExternalId: string;
  since: { occuredAt: string };
  limit: number;
}): Promise<HasMetadata<AsyncTaskFailureEvent>[]> => {
  const tasks = await db.asyncTaskFailureEvent
    .where('asyncTaskExternalId')
    .equals(asyncTaskExternalId)
    .and((task) => task.occuredAt > since.occuredAt)
    .sortBy('occuredAt');
  return tasks.slice(0, limit) as HasMetadata<AsyncTaskFailureEvent>[];
};
