import { getUriFromBlob } from '../../../../__nonpublished_modules__/react-image-selection/src/getUriFromBlob';
import { locallyPersistedPhotoDao } from '../../../../data/dao/locallyPersistedPhotoDao';
import { AsyncTaskUploadPhoto } from '../../../../domain/objects/AsyncTaskUploadPhoto';
import { LocallyPersistedPhoto } from '../../../../domain/objects/LocallyPersistedPhoto';
import { UnexpectedCodePathError } from '../../../../utils/errors/UnexpectedCodePathError';

/**
 * there are three spots a photo can be fetched from this task
 * - the output.hostedUrl, which is the best option, but may not be defined yet
 * - the input.locallyPersistedPhotoKey, which should be used if defined, but will only be defined for local blobs
 * - the input.url, which will always be defined, but if locallyPersistedPhotoKey is defined may eventually point to a garbage collected blob (which is why we use locallyPersistedPhotoKey if exists)
 */
export const getBestPhotoFromAsyncTaskUploadPhoto = async ({
  task,
}: {
  task: AsyncTaskUploadPhoto;
}) => {
  // if we've uploaded the photo, this is the best option
  if (task.output?.hostedUrl) return task.output.hostedUrl;

  // if there's a locallyPersistedPhotoKey, use that to make sure we access a photo that can't be garbage collected
  if (task.input.locallyPersistedPhotoKey) {
    const locallyPersistedPhoto: LocallyPersistedPhoto | null =
      await locallyPersistedPhotoDao.findByUnique({
        key: task.input.locallyPersistedPhotoKey,
      });
    if (!locallyPersistedPhoto)
      throw new UnexpectedCodePathError(
        'could not find locallyPersistedPhoto by task.input.locallyPersistedPhotoKey',
        { task },
      );
    const url = await getUriFromBlob(locallyPersistedPhoto.blob);
    return url;
  }

  // last but not least, just use the input url
  return task.input.url;
};
