export enum AsyncTaskExecutorType {
  REGISTERED_IN_MEMORY_FUNCTION = 'REGISTERED_IN_MEMORY_FUNCTION',
}
export interface AsyncTaskExecutor {
  type: AsyncTaskExecutorType;
  key: string;
}

export type SerializableObject = any; // TODO: fix this type
// | string
// | number
// | boolean
// | null
// | { [property: string]: SerializableObject }
// | SerializableObject[];

export enum AsyncTaskStatus {
  WAITING = 'WAITING',
  QUEUED = 'QUEUED',
  ATTEMPTED = 'ATTEMPTED',
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}
export interface AsyncTask {
  requestedAt: string;

  /**
   * the external id, unique key, of this task
   */
  externalId: string;

  /**
   * an identifier to the logic that executes this asnyc task
   */
  executor: AsyncTaskExecutor;

  /**
   * the external id of an existing, waiting async-task which the successful execution of this task triggers
   */
  triggers: string | null;

  /**
   * the input to execute the task with
   */
  input: SerializableObject;

  /**
   * the output of the successful execution of this task
   */
  output: null | SerializableObject;

  /**
   * the tasks current status
   */
  status: AsyncTaskStatus;

  /**
   * the reason for the task's current status
   */
  reason: string | null;
}

export type AsyncTaskExecutorImplementation<T extends AsyncTask> = (
  task: T,
) => Promise<
  | {
      output: AsyncTask['output'];
      status?: undefined;
      reason?: undefined;
    }
  | {
      output?: undefined;
      status: AsyncTaskStatus;
      reason: string | null;
    }
  | void
>;

export const ASYNC_TASK_TO_BE_FULFILLED_STATUSES = [
  AsyncTaskStatus.WAITING,
  AsyncTaskStatus.QUEUED,
  AsyncTaskStatus.ATTEMPTED,
  AsyncTaskStatus.FAILED,
];
