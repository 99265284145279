import { HasMetadata } from 'simple-type-guards';

import { AsyncTask, AsyncTaskStatus } from '../../../domain/objects/AsyncTask';
import { db } from '../db';

export const findAllByStatus = async <T extends AsyncTask>({
  status,
}: {
  status: AsyncTaskStatus;
}): Promise<HasMetadata<T>[]> => {
  const tasks = await db.asyncTask
    .where('status')
    .equals(status)
    .sortBy('requestedAt');
  return tasks as HasMetadata<T>[];
};
