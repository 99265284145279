import { HasMetadata } from 'simple-type-guards';

import { LocallyPersistedPhoto } from '../../../domain/objects/LocallyPersistedPhoto';
import { db } from '../db';

export const findByUnique = async ({
  key,
}: {
  key: string;
}): Promise<HasMetadata<LocallyPersistedPhoto> | null> => {
  const photo = await db.locallyPersistedPhotoDao
    .where('key')
    .equals(key)
    .first();
  return (photo as HasMetadata<LocallyPersistedPhoto>) ?? null;
};
