import { css, EmotionCache, Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { ReactChild } from 'react';

import { EmotionCacheProviderWithSSR } from '../../../__nonpublished_modules__/nextjs-material-ui/EmotionCacheProviderWithSSR';
import { fonts } from '../../../constants/fonts';
import { theme } from '../../../constants/theme';

export const ThemeProvider = ({
  children,
  serverSideEmotionCache,
}: {
  children: ReactChild;
  serverSideEmotionCache?: EmotionCache;
}) => {
  return (
    <>
      <EmotionCacheProviderWithSSR
        serverSideEmotionCache={serverSideEmotionCache ?? null} // pass in the serverSide cache when available; // TODO: see if we can globally determine this per request; or just create it each time when on server-side, without caching
      >
        <MuiThemeProvider theme={theme}>
          {/* set nice css defaults that mui defines */}
          <CssBaseline />
          {/* set defaults that we define; TODO: dedupe -vs- CssBaseline */}
          <Global
            styles={css`
              html,
              body {
                /* remove default padding from html and body */
                margin: 0;
                padding: 0;

                /* set default font */
                font-family: ${fonts.static};
                font-weight: 400;

                /* display all line breaks; https://stackoverflow.com/questions/29401711/newlines-character-ignored-by-jsx-table */
                white-space: pre-line;

                /* branded background color */
                background-color: ${'white'};
                min-height: 100%;

                /* prevent screen zoomies; https://stackoverflow.com/a/62165035/15593329 */
                touch-action: pan-x pan-y;
              }
              textarea:focus,
              input:focus {
                outline: none;
              }
            `}
          />
          {children}
        </MuiThemeProvider>
      </EmotionCacheProviderWithSSR>
    </>
  );
};
