// e.g., in `src/utils/log.ts
import { generateLogMethods, LOG_LEVEL } from 'simple-leveled-log-methods';

/*
  define the minimal log level
*/
const defaultLogLevel =
  process.env.NODE_ENV == 'production'
    ? LOG_LEVEL.DEBUG // if on production, log all logs
    : LOG_LEVEL.DEBUG; // otherwise, we're running locally and should only default to show info and above
const minimalLogLevel = (process.env.LOG_LEVEL as LOG_LEVEL) || defaultLogLevel; // override the default, if specified

/*
  define the log methods
*/
export const log = generateLogMethods({ minimalLogLevel });
