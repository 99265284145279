const apiEndpoint =
  'https://yyhz7xm1h2.execute-api.us-east-1.amazonaws.com/prod/upload';

export const uploadImage = async (
  data: { base64: string } | { url: string },
) => {
  const options = {
    method: 'POST',
    mode: 'cors' as const,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(apiEndpoint, options);
  const responseData = await response.json();
  const url = responseData.url;
  return { url } as { url: string };
};
