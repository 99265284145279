import { HasMetadata } from 'simple-type-guards';

import { AsyncTaskFailureEvent } from '../../../domain/objects/AsyncTaskFailureEvent';
import { db } from '../db';
import { findByUnique } from './findByUnique';

export const upsert = async ({
  event,
}: {
  event: AsyncTaskFailureEvent;
}): Promise<HasMetadata<AsyncTaskFailureEvent>> => {
  await db.asyncTaskFailureEvent.put(event);
  const upserted = (await findByUnique({
    asyncTaskExternalId: event.asyncTaskExternalId,
    occuredAt: event.occuredAt,
  }))!;
  return upserted;
};
