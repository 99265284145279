import { HasMetadata } from 'simple-type-guards';
import useSWR, { mutate as invalidate } from 'swr';

import { Lot } from '../../../../domain';
import { fetchAgainstSvcGateway } from '../util/createSvcGatewayFetcher';

export const clearQueryGetLotCache = (input: { lotUuid: string }) =>
  invalidate({ endpoint: '/lot/get', data: input });

export const useQueryGetLot = (input: { lotUuid: string }) => {
  const { data, mutate, error } = useSWR<{ lot: HasMetadata<Lot> }>(
    { endpoint: '/lot/get', data: input },
    fetchAgainstSvcGateway,
  );
  return { data: data?.lot, loading: !data, error, mutate };
};
