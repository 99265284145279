import { HasMetadata } from 'simple-type-guards';

import { AsyncTask } from '../../../domain/objects/AsyncTask';
import { db } from '../db';

export const findByUnique = async <T extends AsyncTask>({
  externalId,
}: {
  externalId: string;
}): Promise<HasMetadata<T> | null> => {
  const task = await db.asyncTask
    .where('externalId')
    .equals(externalId)
    .first();
  return (task as HasMetadata<T>) ?? null;
};
